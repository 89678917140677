import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NgxSmartModalService } from 'ngx-smart-modal';

@Component({
    selector: 'app-skill',
    templateUrl: './skill.component.html',
    styleUrls: ['./skill.component.scss']
})
export class SkillComponent implements OnInit {

    isVideoPlaying = false;
    videoUrl: SafeResourceUrl;
    videoLink: string;

    constructor(public ngxSmartModalService: NgxSmartModalService,
        private sanitizer: DomSanitizer
    ) {
    }

    ngOnInit() {
    }

    transform(url: string): SafeResourceUrl {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }

    /**
     * Permite darle play al video
     */
    playVideo() {
        this.isVideoPlaying = true; 
        const youtubeId = 'mUKDs3lvy0o'; // Reemplazar con el ID del video
        this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
            `https://www.youtube.com/embed/${youtubeId}?autoplay=1`
        );
        this.videoLink = `https://www.youtube.com/watch?v=${youtubeId}`;
    }

    /**
     * Permite cerrar la vista video
     */
    closeVideo() {
        this.isVideoPlaying = false;
    }

}