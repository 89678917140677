<nav #navpafar class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied" ngStickyNav stickyClass="sticky-box-shadow">
    <div class="container">
        <div class="content-logo">            
            <!--<a class="navbar-brand" routerLink="/"><span>P</span>afar</a>-->
            <a *ngIf="!displayLogo" class="navbar-brand" routerLink="/"><img src="assets/images/logo_pafar_blanco.png" alt=""></a>
            <a *ngIf="displayLogo" class="navbar-brand" routerLink="/"><img src="assets/images/logo_pafar_color.png" alt=""></a>
            
        </div>
        <button class="navbar-toggler" type="button" (click)="toggleClass()">
            <span class="burger-menu">
                <span class="top-bar"></span>
                <span class="middle-bar"></span>
                <span class="bottom-bar"></span>
            </span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item"><span class="nav-link" (click)="onClick('home')">Home</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('about')">¿Quienes Somos?</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('team')">Alianzas</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('services')">Productos y Servicios</span></li>                
                <li class="nav-item"><span class="nav-link" (click)="onClick('blog')">Representaciones</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('partner')">Sedes</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('contact')">Contacto</span></li>
            </ul>
            <ul class="navbar-nav ml-auto for-responsive">
                <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('home')">Home</span></li>
                <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('about')">¿Quienes Somos?</span></li>
                <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('team')">Alianzas</span></li>
                <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('services')">Productos y Servicios</span></li>                
                <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('blog')">Representaciones</span></li>
                <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('partner')">Sedes</span></li>
                <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('contact')">Contacto</span></li>
            </ul>
        </div>
    </div>
</nav>